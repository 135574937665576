<template>
  <div>
    <el-row :gutter="30">
      <el-col :span="12">
        <div class="form-group">
          <el-form-item>
            <label class="field-title-label">
              Field Title <span class="red-asterisk">*</span>
            </label>
            <el-input
              type="text"
              maxlength="100"
              v-model="field.label"
              placeholder="Enter Field Title"
              :readonly="hasEntityVariables"
              @input="validateInput"
            ></el-input>
            <p
              v-if="field.label && !field.label.length"
              class="error"
            > *Label is required*</p>
            <FormError error-key="label" />
          </el-form-item>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="form-group">
          <el-form-item label="Description">
            <el-input
              type="textarea"
              v-model="field.description"
              placeholder="Enter Description Here"
            ></el-input>
            <FormError error-key="description" />
          </el-form-item>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import FormError from "@/components/form/FormError";
export default {
  name: "templates-formComponents-TitleAndDescription",
  components: { FormError },
  props: ["field", "hasEntityVariables"],
  data() {
    return {};
  },
  methods: {
    validateInput() {
      const label = this.field.label; 
      const specialCharRegex = /^[!@#$%^&*()\-_=+{}[\]|:;"'<>,.?/\\~`]/;
      this.labelError = "";

      if (label.trim() === "") {
        this.field.label = null; 
        return; 
      }

      if (specialCharRegex.test(label)) {
        this.field.label = label.replace(specialCharRegex, '');
      } else {
        this.field.label = label;
      }
    }
  },
};
</script>

<style lang="scss">
.field-title-label {
  /* Your field title styles here */
}

.red-asterisk {
  color: red;
}
</style>